<template>
    <div class="dvrem_juram">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="dvrem_juram_title">
                        <span>{{ 'durem' | translate }}</span>{{ 'juram' | translate }}
                        <div class="select">
                            <Select v-model="selectedCategory" style="width:200px" aria-placeholder="Salbar"
                                    :placeholder="'select' | translate">
                                <Option v-for="item in categorys" :value="item.id" :key="item.id">
                                    <div v-if="language.short == 'mn'">{{ item.duremangilal }}</div>
                                    <div v-else>{{ item.duremangilal_en }}</div>
                                </Option>
                            </Select>
                            <Button shape="circle" icon="ios-search" style="margin-left: 10px" @click="getGETdurem"></Button>
                        </div>
                    </div>
                    <div v-if="loading" class="loading-wrap">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div class="dvrem_juram_table">
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">№</th>
                                <th scope="col">{{ 'dur_juram' | translate }}</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :key="index" v-for="(list, index) in lists">
                                <td>{{ index + 1 }}</td>
                                <td v-if="language.short == 'mn'">{{ list.durem_mn }}</td>
                                <td v-else>{{ list.durem_en }}</td>
                                <td>
                                    <div class="view">
                                        <a v-if="list.file != null" :href="list.file" target="_blank">{{ 'watch' | translate }}</a>
                                        <a v-else :href="list.link" target="_blank">{{ 'watch' | translate }}</a>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagenation">
                        <Page @on-change="changePage"
                              :current-page="current_page"
                              :page-size="10"
                              :total="total"
                              size="small"/>
                    </div>
                </div>



<!--      <div v-if="loading" class="loading-wrap">-->
<!--        <div class="lds-ellipsis">-->
<!--          <div></div>-->
<!--          <div></div>-->
<!--          <div></div>-->
<!--          <div></div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-md-12">-->
<!--            <div class="dvrem_juram_title">-->
<!--              <span>{{ 'durem' | translate }}</span>{{ 'juram' | translate }}-->
<!--              <div class="select">-->
<!--                <Select v-model="selectedCategory" style="width:200px" aria-placeholder="Salbar" :placeholder="'select' | translate">-->
<!--                  <Option v-for="item in categorys" :value="item.id" :key="item.id">-->
<!--                       <div v-if="language.short == 'mn'">{{ item.duremangilal }}</div>-->
<!--                       <div v-else>{{ item.duremangilal_en }}</div>-->
<!--                  </Option>-->
<!--                </Select>-->
<!--                <Button shape="circle" icon="ios-search" style="margin-left: 10px" @click="getGETdurem"></Button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="dvrem_juram_table">-->
<!--              <table class="table">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <th scope="col">№</th>-->
<!--                  <th scope="col">{{ 'dur_juram' | translate }}</th>-->
<!--                  <th scope="col"></th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                 <tr :key="index" v-for="(list, index) in lists">-->
<!--                   <td>{{index+1}}</td>-->
<!--                   <td v-if="language.short == 'mn'">{{ list.durem_mn}}</td>-->
<!--                   <td v-else>{{ list.durem_en}}</td>-->
<!--                   <td><div class="view"><a :href="list.link" target="_blank">{{ 'watch' | translate }}</a></div></td>-->
<!--                 </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
<!--            <div class="pagenation">-->
<!--              <Page  @on-change="changePage"-->
<!--                     :current-page="current_page"-->
<!--                     :page-size="10"-->
<!--                     :total="total"-->
<!--                     size="small"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import {GET_DUREM, GET_DUREM_ANGILAL, GET_DUREM_BY_ID} from "../graphql/queries";
import {mapGetters} from 'vuex'

export default {
    components: {},
    data() {
        return {
            loading: true,
            lists: [],
            categorys: [],
            selectedCategory: null,
            current_page: 1,
            last_page: 1,
            total: 0,
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ])
    },

    mounted() {
        //this.getGET_DUREM();
        this.getGET_category();
        this.getGETdurem();

    },
    methods: {
        getGET_category() {
            this.$apollo.query({query: GET_DUREM_ANGILAL}).then(({data}) => {
                this.categorys = data.ds_durem_angilal_view;
            })
        },
        getGETdurem() {
            this.loading = true;
            if (this.selectedCategory) {
                this.$apollo.query({
                    query: GET_DUREM_BY_ID,
                    variables: {page: this.current_page, size: 10, cat_id: this.selectedCategory.toString()}
                }).then(({data}) => {
                    this.lists = data.paginate.ds_durem_juram_view;
                    this.total = data.paginate.total;
                    this.loading = false;
                });
            } else {
                this.$apollo.query({
                    query: GET_DUREM,
                    variables: {page: this.current_page, size: 10}
                }).then(({data}) => {
                    this.lists = data.paginate.ds_durem_juram_view;
                    this.total = data.paginate.total;
                    this.loading = false;
                })
            }
        },
        changePage(page) {
            this.current_page = page;
            this.getGETdurem();
        },
    },

}


</script>

<style scoped>

</style>
